import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dfc2c394 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1701a8ca = () => interopDefault(import('../pages/dataset/_id/index.vue' /* webpackChunkName: "pages/dataset/_id/index" */))
const _3fc373d0 = () => interopDefault(import('../pages/dataset/_id/analyze/_variable.vue' /* webpackChunkName: "pages/dataset/_id/analyze/_variable" */))
const _55346ad0 = () => interopDefault(import('../pages/dataset/_id/visualize/_variable.vue' /* webpackChunkName: "pages/dataset/_id/visualize/_variable" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _dfc2c394,
    name: "index"
  }, {
    path: "/dataset/:id",
    component: _1701a8ca,
    name: "dataset-id"
  }, {
    path: "/dataset/:id?/analyze/:variable?",
    component: _3fc373d0,
    name: "dataset-id-analyze-variable"
  }, {
    path: "/dataset/:id?/visualize/:variable?",
    component: _55346ad0,
    name: "dataset-id-visualize-variable"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
